

import {
    Livewire,
    Alpine,
} from "../../vendor/livewire/livewire/dist/livewire.esm";

import calc_relative_time from "./frop/relative_time.js";

import "../../public/vendor/statamic/frontend/js/helpers.js";
import Precognition from 'laravel-precognition-alpine';
// import function to register Swiper custom elements
import { register } from 'swiper/element/bundle';
// register Swiper custom elements
register();

// import any alpine components, stores or data
import "./alpine/BackButton.js";
import "./alpine/Carousel.js";
import "./alpine/ImageComparison.js";
import "./alpine/LatestNews.js";
import "./alpine/LatestNewsPage.js";
import "./alpine/ReadingProgress.js";
import "./alpine/TextBox.js";
import "./alpine/TopMenu.js";
import "./alpine/VideoPlayer.js";
import "./alpine/VideoTable.js";
import "./alpine/UrlUpdater.js";
import "./alpine/HorizSlider.js";
import "./alpine/CookieNotice.js";
import "./alpine/ScrollText.js";
import "./alpine/Strimler.js";
import "./alpine/FynVerticalVideo.js";
import "./alpine/BeyondWordsPlayer.js";
import "./alpine/WeatherModule.js";

Alpine.plugin(Precognition);
Livewire.start();

document.addEventListener("DOMContentLoaded", function (event) {
    calc_relative_time();
});