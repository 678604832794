import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('reel', (id) => ({
    id: id,
    player: null,
    muted: true,
    textVisible: false,
    init() {
        if (!window.frop_players) {
            window.frop_players = []
        }
        if (window.frop_players && window.frop_players[this.id]) {
            this.player = window.frop_players[this.id];
            this.player.setControls(true);

        } else {
            window.frop_players[this.id] = this.init.bind(this);
        }
    },
    share(title, text, slug, location) {
        const url = `https://tv2fyn.dk/${location}/${slug}`;
        if (navigator.share) {
            navigator.share({ title, text, url })
                .catch((error) => console.log('Error sharing', error));
        } else {
            // Set customized TV 2 Fyn overlay
            console.log('Share not supported on this browser, do it the old way.', url);
        }
    },
    togglePlay() {
        if (!this.player) return
        if (this.player.getState() == 'playing') {
            this.player.pause()
        } else {
            this.player.play()
        }

    },
    toggleMute() {
        if (!this.player) return;
        if (this.player.getMute() === true) {
            this.player.setMute(false)
            this.muted = !this.muted
        } else {
            this.player.setMute(true)
            this.muted = !this.muted

        }
    },
    toggleText() {
        this.textVisible = !this.textVisible
    },
    play() {
        if (!this.player) return;
        this.player.play()

    },
    pause() {
        if (!this.player) return;

        if (this.player.getState() === 'playing') {
            this.player.pause()
        }
    }


}))