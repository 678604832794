import { Alpine } from "../../../vendor/livewire/livewire/dist/livewire.esm";

Alpine.data('strimler', (id) => ({
    id: id,
    player: null,
    muted: true,
    init() {
        if (window.frop_strimmel_player_muted === undefined) {
            window.frop_strimmel_player_muted = true;
        }
        if (!window.frop_players) window.frop_players = [];
        if (!window.frop_players[this.id]) window.frop_players[this.id] = () => {
            this.player = window.frop_players[this.id];
            if (this.$refs.append.firstElementChild != this.$refs.strimmel) this.pause();
            this.player.on('time', (e) => {
                const width = `${e.position / this.player.getDuration() * 100}%`;
                this.$refs.progress1.style.width = width;
                this.$refs.progress2.style.width = width;
            });
            this.muted = this.player.getMute();
            this.player.on('mute', (e) => {
                this.muted = e.mute;
                window.frop_strimmel_player_muted = e.mute;
            });
        }
    },
    share(title, text, slug) {
        const url = `https://tv2reg-web.test/strimler/${slug}`;
        if (navigator.share) {
            navigator.share({ title, text, url })
                .catch((error) => console.log('Error sharing', error));
        } else {
            console.log('Share not supported on this browser, do it the old way.', url);
        }
    },
    play() {
        if (!this.player) return;
        this.player.play();
        this.player.setMute(window.frop_strimmel_player_muted);
    },
    pause() {
        if (!this.player) return;
        this.player.pause();
    },
    play_pause() {
        if (!this.player) return;
        const state = this.player.getState();
        if (state == 'playing') {
            this.pause();
        } else if (state == 'paused' || state == 'idle') {
            this.play();
        }
    },
    skip(amount) {
        if (!this.player) return;
        this.player.seek(this.player.getPosition() + amount);
    },
    toggle_mute() {
        if (this.muted) {
            this.unmute();
        } else {
            this.mute();
        }
    },
    mute() {
        if (!this.player) return;
        this.player.setMute(true);
    },
    unmute() {
        if (!this.player) return;
        this.player.setMute(false);
        this.player.setVolume(100);
    },
}));